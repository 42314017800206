import { FeatureLink } from '@common/navigation/feature-link';

export const featureLinks: FeatureLink[] = [
  {
    category: 'Finance Report',
    label: 'Finance Report',
    icon: 'attach_money',
    path: 'finance-report',
    permissionsToDisplay: [
      'GET/v1/finance-report'
    ],
    tags: ['finance report']
  },
  {
    category: 'File Conversions',
    label: 'File Conversions',
    icon: 'switch_camera',
    path: 'file-conversions',
    permissionsToDisplay: [
      'POST/v1/amazon-sync-service/process-amazon-review-conversions'
    ],
    tags: ['file conversions']
  },
  {
    category: 'Batches',
    label: 'Printed Batches',
    icon: 'local_shipping',
    path: 'printed-batches',
    permissionsToDisplay: ['GET/v1/shopify-orders/{store}/batch-fulfillments'],
    tags: ['DC']
  },
  {
    category: 'Batches',
    label: 'Barcode Scanning',
    icon: 'receipt',
    path: 'barcode-functions',
    permissionsToDisplay: [
      'GET/v1/shopify-orders/order-or-tracking/reprint/{identity}',
      'GET/v1/shopify-orders/{store}/ready-to-print/{orderNumber}',
      'GET/v1/shopify-orders/{store}/ready-to-print/{orderNumber}'
    ],
    tags: ['Barcode functions']
  },
  {
    category: 'Reports',
    label: 'Reports',
    icon: 'insert_chart',
    path: 'reports',
    permissionsToDisplay: [
      'GET/v1/report-generator/reports',
      'GET/v1/report-generator/reports',
      'GET/v3/data-warehouse/excelsior/report',
      'GET/v2/data-warehouse/excelsior/fulfillments'
    ],
    tags: [
      'orders by age',
      'orders by status',
      'ready to print',
      'orders by box size',
      'orders by carrier service',
      'print jobs',
      'fulfillments'
    ]
  },
  {
    category: 'Reports',
    label: 'Automated Reports',
    icon: 'mail_outline',
    path: 'automated-reports',
    permissionsToDisplay: [
      'GET/v1/business-reports/report-configurations',
      'GET/v1/business-reports/report-configurations/{report_id}/recipients'
    ],
    tags: [
      'automated reports'
    ]
  },
  {
    category: 'Operations Conveyor Management',
    label: 'Manage Item Master',
    icon: 'stars',
    path: 'manage-item-master',
    permissionsToDisplay: [
      'GET/v1/dc-automations-item-master/query-item-master-details'
    ],
    tags: [
      'manage item master'
    ]
  },
  {
    category: 'Operations Conveyor Management',
    label: 'Manage Barcodes',
    icon: 'texture',
    path: 'barcode-aliases',
    permissionsToDisplay: [
      'GET/v1/dc-automations-item-master/query-barcode-aliases'
    ],
    tags: [
      'manage item master'
    ]
  },
  {
    category: 'Packaging',
    label: 'Boxes',
    icon: 'grid_on',
    path: 'boxes',
    permissionsToDisplay: [
      'GET/v1/shopify-products/get-products',
      'GET/v1/box-packer/overview',
      'GET/v1/box-packer/update/{sku}/{status}',
      'GET/v1/box-packer/products'
    ],
    tags: ['simulator']
  },
  {
    category: 'Packaging',
    label: 'Box Packing',
    icon: 'ballot',
    path: 'boxes/orders',
    permissionsToDisplay: ['GET/v1/box-packer/get-order/{store}/{orderNumber}'],
    tags: ['orders']
  },
  {
    category: 'Inventory',
    label: 'SKU Inventory',
    icon: 'account_balance',
    path: 'sku-inventory',
    permissionsToDisplay: ['GET/v2/excelsior-wms/sku-inventory'],
    tags: ['product']
  },
  {
    category: 'Order',
    label: 'Order',
    icon: 'description',
    path: 'order',
    permissionsToDisplay: [
      'GET/v1/shopify-orders/order-or-tracking/reprint/{identity}',
      'GET/v1/excelsior-customer-service/{store}/recreate/order/{orderNumber}',
      'GET/v1/shopify-orders/{store}/recreate-order/{order}',
      'GET/v1/shopify-orders/{store}/split-order/{order}',
      'GET/v1/excelsior-customer-service/{store}/address-review/{orderNumber}',
      'GET/v1/excelsior-customer-service/{store}/recreate-any/order/{orderNumber}',
      'GET/v1/shopify-orders/{store}/hold/{orderNumber}',
      'GET/v1/shopify-orders/{store}/release/{orderNumber}',
      'GET/v1/shopify-orders/{store}/{order}/process/{user}'
    ]
  },
  {
    category: 'Packaging',
    label: 'Batch Pack Boxes',
    icon: 'save_alt',
    path: 'batch-pack-boxes',
    permissionsToDisplay: [
      'POST/v1/shopify-orders/get-orders-by-box',
      'POST/v1/shopify-orders/assign-orders-by-box'
    ],
    tags: ['orders']
  },
  {
    category: 'Facebook',
    label: 'Facebook Report',
    icon: 'thumb_up',
    path: 'facebook',
    permissionsToDisplay: [
      'GET/v1/report-generator/facebook',
      'GET/v1/report-generator/current-totals',
      'GET/v1/report-generator/historical-spend-revenue/{date}',
      'GET/v1/report-generator/facebook/{from}/{to}',
      'GET/v1/facebook-marketing/proxy-business-ad-account-insights'
    ],
    tags: ['ROI', 'Revenue', 'Spend', 'Shopify', 'Tiktok']
  },
  {
    category: 'Facebook',
    label: 'Facebook Comments Moderation',
    icon: 'thumbs_up_down',
    path: 'facebook/comments-moderation',
    permissionsToDisplay: [
      'POST/v1/hide-comment',
      'POST/review-comments',
      'POST/flag-comments-for-followup'
    ],
    tags: ['Review', 'Followup']
  },
  {
    category: 'Facebook',
    label: 'Facebook Comments',
    icon: 'chat',
    path: 'facebook/comments',
    permissionsToDisplay: ['GET/comments', 'GET/get-comment-context']
  },
  {
    category: 'Shipment',
    label: 'Shipment Review',
    icon: 'call_split',
    path: 'shipment-review',
    permissionsToDisplay: [
      'GET/v1/shipment-reviews',
      'GET/v1/shipment-reviews/mid-zone-management/{brand}'
    ],
    tags: [
      'Mid-zone',
      'DC1',
      'DC2',
      'Non-preferred',
      'Backorder',
      'Non-fulfillable',
      'Oversized'
    ]
  },
  {
    category: 'Reports',
    label: 'Replenishment Report',
    icon: 'table_view',
    path: 'replenishment-report',
    permissionsToDisplay: [
      'GET/v1/data-warehouse/excelsior/report-replenishment-skus'
    ],
    tags: ['SKU counts', 'Backorder']
  },
  {
    category: 'Facebook',
    label: 'FB Top Creatives',
    icon: 'insights',
    path: 'creatives',
    permissionsToDisplay: [
      'GET/v1/facebook-marketing/current-creatives-report',
      'GET/v1/facebook-marketing/creative-name-completion'
    ],
    tags: [
      'ad',
      'clicks',
      'impressions',
      'spend',
      'purchases',
      'revenue',
      'ROAS',
      'CPP',
      'AOV',
      'CR',
      'Accounts'
    ]
  },
  {
    category: 'Ad management',
    label: 'Ad rules',
    icon: 'rule',
    path: 'ad-rules',
    permissionsToDisplay: [
      'GET/v1/facebook-ad-perf/rules',
      'GET/v1/facebook-ad-perf/dashboard-metrics',
      'GET/v1/facebook-ad-perf/rules/events',
      'GET/v1/facebook-ad-perf/rules/jobs',
      'GET/v1/facebook-ad-perf/trigger-observations',
      'GET/v1/facebook-ad-perf/rules/evaluate',
      'GET/v1/facebook-ad-perf/rules/settings'
    ],
    tags: ['Logs', 'Jobs', 'Evaluations', 'Ad data', 'System logs']
  },
  {
    category: 'Ad management',
    label: 'Ad limits',
    icon: 'lock_clock',
    path: 'ad-limit/summary',
    permissionsToDisplay: [
      'GET/v1/facebook-marketing/page-ad-volume-summary',
      'GET/v1/facebook-marketing/ads-lifetime/{id}',
      'GET/v1/facebook-marketing/page-ad-volume-history/{id}'
    ],
    tags: ['limit', 'enforcement']
  },
  {
    category: 'Facebook',
    label: 'Facebook Ad Manager',
    icon: 'engineering',
    path: 'facebook-ad-manager',
    permissionsToDisplay: [
      'GET/v1/facebook-marketing/observations',
      'GET/v1/facebook-marketing/multiday-observations'
    ],
    tags: [
      'ad',
      'clicks',
      'impressions',
      'spend',
      'purchases',
      'revenue',
      'ROAS',
      'CPP',
      'AOV',
      'CR',
      'Accounts'
    ]
  },
  {
    category: 'Fraud',
    label: 'Fraud management',
    icon: 'block',
    path: 'fraud',
    permissionsToDisplay: ['GET/v1/fraud-api/emails'],
    tags: ['ban']
  },
  {
    category: 'Facebook',
    label: 'Facebook attributions',
    icon: 'add_link',
    path: 'facebook-attribution',
    permissionsToDisplay: [
      'GET/v1/facebook-attribution/attributions',
      'GET/v1/facebook-attribution/ad-id-validity-report',
      'GET/v1/facebook-attribution/summary/{mode}',
      'GET/v1/facebook-attribution/summary/{mode}',
      'GET/v1/facebook-attribution/clicks',
      'GET/v1/facebook-attribution/order-attribution/{order_id}'
    ],
    tags: ['Tags', 'Clicks', 'Revenue', 'Attribution rate']
  },
  {
    category: 'Facebook',
    label: 'Facebook recommendations',
    icon: 'recommend',
    path: 'recommendation',
    permissionsToDisplay: ['GET/v1/facebook-marketing/recommendations']
  },
  {
    category: 'Reports',
    label: 'Inventory snapshot report',
    icon: 'store',
    path: 'inventory-snapshot-report',
    permissionsToDisplay: [
      'GET/v1/business-reports/inventory-snapshot/{date}/{hour}'
    ]
  },
  {
    category: 'Reports',
    label: 'Discount codes report',
    icon: 'stars',
    path: 'discount-codes-report',
    permissionsToDisplay: [
      'GET/v1/business-reports/discount-codes',
      'GET/v1/business-reports/discount-code-frequency/discount-codes-usage-per-day'
    ]
  },
  {
    category: 'Reports',
    label: 'Carrier Invoice Audit',
    icon: 'local_atm',
    path: 'carrier-invoice',
    permissionsToDisplay: [
      'GET/v1/accounting-snowflake/list-carrier-services'
    ]
  },
  {
    category: 'Reports',
    label: 'Discount codes frequency report',
    icon: 'discount',
    path: 'discount-frequency-report',
    permissionsToDisplay: [
      'GET/v1/discount-tiers/campaign'
    ]
  },
  {
    category: 'Discounts',
    label: 'Discount tier campaigns',
    icon: 'discount',
    path: 'discount-tier',
    permissionsToDisplay: [
      'GET/v1/discount-tiers/campaign'
    ]
  },
  {
    category: 'Order progression',
    label: 'Fulfillment metrics',
    icon: 'score',
    path: 'fulfillment-metrics',
    permissionsToDisplay: [
      'POST/v1/excelsior-wms/fulfill-on-scan',
      'POST/v1/excelsior-wms/query-projected-metrics-updates',
      'POST/v1/excelsior-wms/update-projected-metrics',
      'POST/v1/excelsior-wms/query-metrics'
    ],
    tags: ['projections']
  },
  {
    category: 'Facebook',
    label: 'Facebook audiences',
    icon: 'people',
    path: 'audience/lookalikes',
    permissionsToDisplay: ['GET/v1/facebook-audience/lookalike'],
    tags: ['ratio', 'adset', 'account', 'audience', 'country', 'lookalikes']
  },
  {
    category: 'Order progression',
    label: 'Order Aging',
    icon: 'view_agenda',
    path: 'order-aging',
    permissionsToDisplay: [
      'GET/v1/data-warehouse/report-order-status-progressions'
    ],
    tags: ['status progression']
  },
  {
    category: 'Facebook',
    label: 'Facebook queries',
    icon: 'query_stats',
    path: 'facebook-query',
    permissionsToDisplay: [
      'GET/v1/facebook-query/queries',
      'GET/v1/facebook-query/queries/{id}',
      'POST/v1/facebook-query/results-csv/{id}'
    ]
  },
  {
    category: 'Facebook',
    label: 'Facebook ad copy',
    icon: 'copy_all',
    path: 'ad-copy',
    permissionsToDisplay: [
      'GET/v1/facebook-copy/ad-jobs',
      'GET/v1/facebook-query/queries/{id}',
      'POST/v1/facebook-query/results-csv/{id}'
    ],
    tags: ['Entity', 'Adset', 'Source', 'Destination', 'Campaign']
  },
  {
    category: 'Marketing',
    label: 'Store creatives for later',
    icon: 'cloud_circle',
    path: 'marketing-creative',
    permissionsToDisplay: [
      'GET/v1/marketing-creative/creatives',
      'PUT/v1/marketing-creative/creatives/{id}'
    ],
    tags: ['Source ad']
  },
  {
    category: 'Products',
    label: 'Update Product Tables',
    icon: 'chrome_reader_mode',
    path: 'update-product-tables',
    permissionsToDisplay: [
    ],
    tags: ['products']
  },
  {
    category: 'Facebook',
    label: 'Facebook adset learning',
    icon: 'school',
    path: 'adset-learning',
    permissionsToDisplay: ['GET/v1/facebook-marketing/adset-learning'],
    tags: ['Success', 'Fail', 'Paused', 'Dynamic']
  },
  {
    category: 'Facebook',
    label: 'Facebook page performance',
    icon: 'speed',
    path: 'page-performance',
    permissionsToDisplay: ['GET/v1/facebook-marketing/page-performance'],
    tags: [
      'Adsets',
      'Orders',
      'Spend',
      'Revenue',
      'Impressions',
      'CPM',
      'CPI',
      'ROAS',
      'CPA',
      'AOV'
    ]
  },
  {
    category: 'Facebook',
    label: 'Facebook bid adjustment',
    icon: 'price_change',
    path: 'facebook-bid-adjustment',
    permissionsToDisplay: ['GET/v1/facebook-marketing/bid-adjust'],
    tags: ['adset']
  },
  {
    category: 'Admin',
    label: 'User management',
    icon: 'verified_user',
    path: 'user-management',
    permissionsToDisplay: [
      'GET/v1/excelsior-iam/users',
      'GET/v1/excelsior-iam/user/{username}'
    ],
    tags: ['permission']
  },
  {
    category: 'Admin',
    label: 'Permissions Management',
    icon: 'spellcheck',
    path: 'permissions-management',
    permissionsToDisplay: [
      'PATCH/v1/excelsior-iam/users-functions'
    ],
    tags: ['permission']
  },
  {
    category: 'Discount Functions',
    label: 'Manage Discount Functions',
    icon: 'functions',
    path: 'discount-functions',
    permissionsToDisplay: [
      'GET/v1/discount-functions/discounts',
      'GET/v1/discount-functions/functions'
    ],
    tags: ['discount-functions']
  },
  {
    category: 'Marketing',
    label: 'Market dashboard',
    icon: 'dashboard',
    path: 'market-dashboard',
    permissionsToDisplay: ['GET/v1/market-dash/query/{mode}'],
    tags: ['Facebook, UTM']
  },
  {
    category: 'Marketing',
    label: 'Shopify Markets',
    icon: 'shopping_cart',
    path: 'shopify-markets',
    permissionsToDisplay: [
      'GET/v1/excelsior-shopify-service/get-markets'
    ],
    tags: ['shopify']
  },
  {
    category: 'Marketing',
    label: 'Marketing Strategy',
    icon: 'device_hub',
    path: 'marketing-strategy',
    permissionsToDisplay: [
      'GET/v1/variants/{id}'
    ],
    tags: ['shopify']
  },
  {
    category: 'Wave Planning',
    label: 'Waves',
    icon: 'waves',
    path: 'shipment-wave',
    permissionsToDisplay: [
      'POST/v1/ship-wave/query-shipments',
      'GET/v1/ship-wave/wave',
      'GET/v1/ship-wave/template'
    ],
    tags: [
      'wave planning',
      'waves',
      'distribution'
    ]
  },
  {
    category: 'Wave Planning',
    label: 'Wave Processing Status',
    icon: 'event_available',
    path: 'wave-processing-status',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-waving/query-wave-processing-status'
    ],
    tags: [
      'wave planning'
    ]
  },
  {
    category: 'Wave Planning',
    label: 'Archive Wave Ids',
    icon: 'archive',
    path: 'archive-wave',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-archive-wave'
    ],
    tags: [
      'wave planning'
    ]
  },
  {
    category: 'Operations SOP',
    label: 'Operations Standard Operating Procedures',
    icon: 'list',
    path: 'standard-operating-procedures',
    permissionsToDisplay: [],
    tags: [
      'operations sop'
    ]
  },
  {
    category: 'Operations Research',
    label: 'Carton Lookup',
    icon: 'style',
    path: 'carton-lookup',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-carton-header'
    ],
    tags: [
      'operations research'
    ]
  },
  {
    category: 'Operations Research',
    label: 'Expedited Not Packed Cartons',
    icon: 'unarchive',
    path: 'non-packed-cartons',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-non-packed-cartons'
    ],
    tags: [
      'operations research'
    ]
  },
  {
    category: 'Operations Conveyor Management',
    label: 'Dock Doors',
    icon: 'shopping_basket',
    path: 'dock-doors',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-shipping-dock-doors'
    ],
    tags: [
      'operations conveyor management'
    ]
  },
  {
    category: 'Operations Conveyor Management',
    label: 'Carton Audit Assignments',
    icon: 'assignment_turned_in',
    path: 'carton-audit-assignments',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/get-carton-audit-assignments'
    ],
    tags: [
      'operations conveyor management'
    ]
  },
  {
    category: 'Order Progression',
    label: 'Singles Line Wave Progression',
    icon: 'cell_wifi',
    path: 'single-line-progression',
    permissionsToDisplay: [
      'GET/v1/dc-automations-wave-progression/query-single-line-progression'
    ],
    tags: [
      'order progression'
    ]
  },
  {
    category: 'Order Progression',
    label: 'UnShipped Priority',
    icon: 'assignment_late',
    path: 'unshipped-priority',
    permissionsToDisplay: [
      'GET/v1/dc-automations-wave-progression/get-unshipped-priority'
    ],
    tags: [
      'order progression'
    ]
  },
  {
    category: 'Operations Conveyor Management',
    label: 'Manage Hardware Mapping',
    icon: 'devices_other',
    path: 'manage-hardware-mapping',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-hardware-mapping'
    ],
    tags: [
      'operations conveyor management'
    ]
  },
  {
    category: 'Operations Picking',
    label: 'Picking Locations',
    icon: 'location_on',
    path: 'picking-locations',
    permissionsToDisplay: [
      'GET/v1/dc-automations-picking/get-picking-locations'
    ],
    tags: [
      'operations picking'
    ]
  },
  {
    category: 'Operations Picking',
    label: 'Picked Units Report',
    icon: 'dialpad',
    path: 'picked-units-reports',
    permissionsToDisplay: [
      'GET/v1/dc-automations-picking/picked-units-reports'
    ],
    tags: [
      'operations picking'
    ]
  },
  {
    category: 'Labor Management',
    label: 'Labor Management',
    icon: 'supervised_user_circle',
    path: 'labor-management',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-lm/get-lm-user-list'
    ],
    tags: [
      'labor management'
    ]
  },
  {
    category: 'Labor Management',
    label: 'MPR Associate Decouple Summary',
    icon: 'group',
    path: 'associate-decouple',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-lm/query-associate-decouple-summary'
    ],
    tags: [
      'labor management'
    ]
  },
  {
    category: 'Labor Management',
    label: 'MPR Associate Summary',
    icon: 'recent_actors',
    path: 'associate-summary',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-associate-summary'
    ],
    tags: [
      'labor management'
    ]
  },
  {
    category: 'Labor Management',
    label: 'MPR Associate Summary by Supervisor',
    icon: 'account_box',
    path: 'associate-summary-by-supervisor',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-lm/query-associate-mpr-summary'
    ],
    tags: [
      'labor management'
    ]
  },
  {
    category: 'Labor Management',
    label: 'Time Usage',
    icon: 'access_time',
    path: 'time-usage',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-lm/time-usage-summary'
    ],
    tags: [
      'labor management'
    ]
  },
  {
    category: 'Wave Planning',
    label: 'Unwaved Summary',
    icon: 'sync_disabled',
    path: 'unwaved-summary',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-unwaved-summary'
    ],
    tags: [
      'wave planning'
    ]
  },
  {
    category: 'Labor Management',
    label: 'MPR Associate Unit Details',
    icon: 'group_work',
    path: 'associate-unit-details',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-associate-unit-details'
    ],
    tags: [
      'labor management'
    ]
  },
  {
    category: 'Labor Management',
    label: 'Attendance Points By Date',
    icon: 'rounded_corner',
    path: 'attendance-points-by-date',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-lm/query-attendance-points-by-date'
    ],
    tags: [
      'labor management'
    ]
  },
  {
    category: 'WMS Operations',
    label: 'Requested Wave Orders',
    icon: 'waves',
    path: 'wave-orders',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-wave-orders'
    ],
    tags: [
      'wms operations'
    ]
  },
  {
    category: 'Labor Management',
    label: 'Labor Management Timesheet',
    icon: 'calendar_today',
    path: 'timesheet',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-lm/query-user-timesheet'
    ],
    tags: [
      'labor management'
    ]
  },
  {
    category: 'Operations Inventory',
    label: 'DC2 Inventory in Totes',
    icon: 'business_center',
    path: 'inventory-in-totes',
    permissionsToDisplay: [
      'GET/v1/dc-automations-picking/query-units-in-totes'
    ],
    tags: [
      'operations inventory'
    ]
  },
  {
    category: 'Order Status',
    label: 'Order Status',
    icon: 'dvr',
    path: 'order-status',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-order-status-report'
    ],
    tags: [
      'order status'
    ]
  },
  {
    category: 'Order Status',
    label: 'Fulfill By Status',
    icon: 'filter_frames',
    path: 'fulfill-by-status',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-order-status-report'
    ],
    tags: [
      'order status'
    ]
  },
  {
    category: 'Order Status',
    label: 'Bridged Status',
    icon: 'settings_input_component',
    path: 'bridged-status',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-bridged-status-report'
    ],
    tags: [
      'order status'
    ]
  },
  {
    category: 'Operations Reporting',
    label: 'Bridged Units Summary',
    icon: 'assessment',
    path: 'bridged-units-summary',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-bridged-units-hour-history'
    ],
    tags: [
      'operations reporting'
    ]
  },
  {
    category: 'Operations Reporting',
    label: 'WMS Dashboard',
    icon: 'pie_chart',
    path: 'wms-dashboard',
    permissionsToDisplay: [
      'GET/v1/dc-automations-dashboard-service/aged-totes'
    ],
    tags: [
      'operations reporting'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Associate Outbound Hour Summary',
    icon: 'alarm_on',
    path: 'associate-outbound-hour-summary',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-associate-hour-summary'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Internal Turn Time',
    icon: 'alarm_add',
    path: 'internal-turn-time',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-key-metrics/get-turn-time-order-loaded',
      'GET/v1/dc-automations-gateway-key-metrics/get-turn-time-order-delivery'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Packing Shorts by Day',
    icon: 'move_to_inbox',
    path: 'packing-shorts',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-packing-shorts'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Associate Outbound Hourly Pivot',
    icon: 'grid_on',
    path: 'associate-outbound-hourly-pivot',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-associate-hourly-pivot'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Associate Outbound Hourly Pivot + RnR',
    icon: 'grid_on',
    path: 'associate-outbound-hourly-pivot-rnr',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-associate-hourly-pivot-rnr'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations TV',
    label: 'UPH Summary',
    icon: 'grid_on',
    path: 'uph-summary',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-tv-reporting-associate-hourly-pivot'
    ],
    tags: [
      'operations tv'
    ]
  },
  {
    category: 'Operations TV',
    label: 'Wave Plan TV',
    icon: 'waves',
    path: 'wave-plan-tv',
    permissionsToDisplay: [
      'GET/v1/dc-automations-wave-plan-service/wave-plan-tv'
    ],
    tags: [
      'operations tv'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Outbound Key Metrics By Tasks',
    icon: 'blur_linear',
    path: 'outbound-key-metrics-by-tasks',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-day-outbound'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Outbound Key Metrics',
    icon: 'insert_chart_outlined',
    path: 'outbound-key-metrics',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-key-metrics/get-outbound-key-metrics'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Fulfilled Shipments',
    icon: 'bar_chart',
    path: 'fulfilled-shipments',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-key-metrics/query-fulfilled-shipments'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations Key Metrics',
    label: 'Picked Units Per Hour',
    icon: 'schedule',
    path: 'picked-units',
    permissionsToDisplay: [
      'GET/v1/dc-automations-picking/query-picked-units'
    ],
    tags: [
      'operations key metrics'
    ]
  },
  {
    category: 'Operations Picking',
    label: 'Picking Shorts Summary',
    icon: 'timeline',
    path: 'picking-shorts-summary',
    permissionsToDisplay: [
      'GET/v1/dc-automations-picking/query-picking-shorts-summary'
    ],
    tags: [
      'operations picking'
    ]
  },
  {
    category: 'Operations Picking',
    label: 'Aged Totes',
    icon: 'alarm_on',
    path: 'aged-totes',
    permissionsToDisplay: [
      'GET/v1/dc-automations-picking/query-aged-totes'
    ],
    tags: [
      'operations picking'
    ]
  },
  {
    category: 'Operations Picking',
    label: 'Picking Shorts Detail',
    icon: 'clear_all',
    path: 'picking-shorts-detail',
    permissionsToDisplay: [
      'GET/v1/dc-automations-picking/query-picking-shorts-detail'
    ],
    tags: [
      'operations picking'
    ]
  },
  {
    category: 'Operations TV',
    label: 'Prod Goals',
    icon: 'beenhere',
    path: 'tv-goals',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-tv-goals'
    ],
    tags: [
      'operations tv'
    ]
  },
  {
    category: 'Operations Picking',
    label: 'Picking Allocations',
    icon: 'room',
    path: 'picking-allocations',
    permissionsToDisplay: [
      'GET/v1/dc-automations-picking/query-picking-allocations'
    ],
    tags: [
      'operations picking'
    ]
  },
  {
    category: 'Order Progression',
    label: 'Task Progression',
    icon: 'assignment',
    path: 'task-progression',
    permissionsToDisplay: [
      'GET/v1/dc-automations-wave-progression/query-task-progression'
    ],
    tags: [
      'order progression'
    ]
  },
  {
    category: 'Operations Research',
    label: 'Task Lookup',
    icon: 'find_in_page',
    path: 'task-lookup',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-task-details-audit'
    ],
    tags: [
      'operations research'
    ]
  },
  {
    category: 'Operations Research',
    label: 'Task Carton Detail',
    icon: 'line_style',
    path: 'task-carton-detail',
    permissionsToDisplay: [
      'GET/v1/dc-automations-gateway-http/query-task-carton-detail'
    ],
    tags: [
      'operations research'
    ]
  },
  {
    category: 'Pii',
    label: 'Pii requests',
    icon: 'privacy_tip',
    path: 'pii-request',
    permissionsToDisplay: ['GET/v1/pii-request/requests'],
    tags: ['pii']
  },
  {
    category: 'Shipping threshold',
    label: 'Shipping threshold',
    icon: 'schedule',
    path: 'shipping-threshold',
    permissionsToDisplay: ['GET/v1/shipping-threshold/schedules'],
    tags: ['shopify']
  }
];
